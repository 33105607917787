<template>
    <section-block>
        <div class="container">
            <div class="row align-items-center">
                <div class="col-12 col-md-7">
                    <h1 class="text-responsive">
                        1.5% Unlimited Cashback on all purchases<sup><a href="#footnote_5">5</a></sup>
                    </h1>
                    <h5 class="text-responsive fw-light mt-md-3">
                        Earn unlimited 1.5% cashback on every single purchase using your Aven Visa Signature card. Yes, even on that coffee and donut.
                    </h5>
                </div>
                <div class="col-12 col-md-5 mt-3 mt-md-0">
                    <div class="position-relative">
                        <img
                            class="img-fluid"
                            src="@/assets/images/pages/marketing/landing/UnlimitedCashback@2x.jpg"
                            alt="Limits"
                        >
                        <div
                            id="cashbackCarousel"
                            class="carousel slide"
                            data-ride="carousel"
                        >
                            <div class="carousel-inner">
                                <div class="carousel-item active">
                                    <img
                                        src="@/assets/images/pages/marketing/landing/CashBackCaption1.png"
                                        class="d-block"
                                        alt="Cashback"
                                        width="288"
                                        height="86"
                                    >
                                </div>
                                <div class="carousel-item">
                                    <img
                                        src="@/assets/images/pages/marketing/landing/CashBackCaption2.png"
                                        class="d-block"
                                        alt="Cashback"
                                        width="288"
                                        height="86"
                                    >
                                </div>
                                <div class="carousel-item">
                                    <img
                                        src="@/assets/images/pages/marketing/landing/CashBackCaption3.png"
                                        class="d-block"
                                        alt="Cashback"
                                        width="288"
                                        height="86"
                                    >
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section-block>
</template>

<script>
    import SectionBlock from '@/components/SectionBlock'
    import { Carousel } from 'bootstrap'

    export default {
        name: 'UnlimitedCashbackSection',
        components: { SectionBlock },
        mounted: function () {
            const myCarousel = document.getElementById('cashbackCarousel')
            const carousel = new Carousel(myCarousel, {
                interval: 1500,
            })
            carousel.cycle()
        },
    }
</script>

<style lang="scss" scoped>
    .carousel {
        position: absolute;
        bottom: 24px;

        .carousel-item {
            padding-left: 24px;
        }
    }
</style>
