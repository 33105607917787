<template>
    <marketing-layout :has-landing-footer="true">
        <div class="has-navbar container-fluid px-0">
            <section-block class="pt-3 pt-md-8 pb-0 pb-sm-1">
                <div
                    id="origination-form"
                    class="container"
                >
                    <div class="row">
                        <div class="col-12 col-md-8 offset-md-2 col-lg-6 offset-lg-0 text-start">
                            <section-header variant="text-primary">
                                Low Variable APRs<sup><a href="#footnote_1">1</a></sup>
                            </section-header>
                            <h1 class="mb-3">
                                <img
                                    class="img-fluid"
                                    :src="require(`@/assets/images/experiments/${heraclesParameter.EXPERIMENT_NAME}/apr.svg`)"
                                    :alt="$t(`experiments.${heraclesParameter.EXPERIMENT_NAME}.aprAltText`)"
                                >
                            </h1>
                            <h3
                                class="text-responsive mb-1"
                                v-html="$t(`experiments.${heraclesParameter.EXPERIMENT_NAME}.heroTitle`)"
                            />
                            <h5
                                class="text-responsive fw-light mb-3 me-lg-3"
                                v-html="$t(`experiments.${heraclesParameter.EXPERIMENT_NAME}.heroSubtitle`)"
                            />
                            <origination-invite-form
                                id-prefix="main-origination-form"
                                class="pe-lg-8"
                                :code-required="codeRequired"
                                ref="originationInviteFormHero"
                                :show-forgot-code-option="showForgotCodeOption"
                                :forgot-code-link="heraclesParameter.JOIN_PATH"
                                show-safe-credit-score
                                cta-text="Check your offer"
                            />
                        </div>
                        <card-hand-medium-large />
                    </div>
                </div>
                <div class="mobile-image" />
            </section-block>

            <card-hand-small />

            <div class="container centered mt-3">
                <hr>
            </div>

            <section-block>
                <div class="container">
                    <div class="row position-relative">
                        <div class="col">
                            <section-header
                                variant="text-primary"
                                class="text-md-center d-md-flex flex-column align-items-center"
                                underline
                            >
                                What is it
                            </section-header>
                            <h3 class="text-responsive mb-5 mb-md-1 mt-2 text-md-center">
                                Home equity rates. Credit card simplicity.
                            </h3>
                            <h3 class="text-responsive text-md-center mb-0">
                                Plus rewards and cash out
                            </h3>
                        </div>
                    </div>
                </div>
            </section-block>

            <divider-line />

            <how-it-works-section />

            <divider-line />

            <limits-section />

            <divider-line />

            <home-equity-section />

            <divider-line />

            <unlimited-cashback-section
                cashback-pct-str="1.5%"
                footnote-number="5"
            />

            <divider-line />

            <card-comparison-section />

            <divider-line />

            <balance-transfer-section />

            <divider-line />

            <fees-section />

            <divider-line />

            <benefits-section />

            <divider-line />

            <home-improvement-section />

            <divider-line />

            <cash-out-section />

            <divider-line />

            <about-us-section />

            <divider-line />

            <section-block>
                <div class="container">
                    <div class="row align-items-top">
                        <testimonial
                            class="col-sm"
                            header="Awesome company and service. Plan to be Aven strong for life."
                            name="Julian"
                            location="California"
                        />
                        <div class="d-block d-md-none container my-5" />
                        <testimonial
                            class="col-sm"
                            header="Between the low interest and excellent customer service it was a &quot;no brainer&quot; to go with Aven."
                            name="William"
                            location="California"
                        />
                    </div>
                </div>
            </section-block>

            <divider-line />

            <faq-section />

            <divider-line />

            <section-block>
                <div class="container">
                    <div class="row align-items-start">
                        <div class="col-sm">
                            <section-header
                                variant="text-primary"
                                underline
                            >
                                Contact Us
                            </section-header>
                            <h2 class="my-2">
                                Reach out with any questions
                            </h2>
                            <h5
                                class="mb-3 text-responsive fw-light"
                                v-html="$t('pages.marketing.landing.reengage.contactInfoHtml')"
                            />
                            <a
                                :href="smsHref"
                                class="btn btn-secondary d-block d-md-inline-block mb-2"
                            >
                                {{ $t('pages.marketing.landing.reengage.messageUs') }}
                            </a>
                        </div>
                        <div class="d-block d-md-none container my-5">
                            <hr>
                        </div>
                        <div class="col-sm">
                            <section-header
                                variant="text-primary"
                                underline
                            >
                                Join
                            </section-header>
                            <h2 class="my-2">
                                Check offers
                            </h2>
                            <origination-invite-form
                                id-prefix="footer-origination-form"
                                class="mw-invite-form"
                                :code-required="codeRequired"
                                :show-forgot-code-option="showForgotCodeOption"
                                :forgot-code-link="heraclesParameter.JOIN_PATH"
                                ref="originationInviteFormFooter"
                                show-safe-credit-score
                                cta-text="Check your offer"
                            />
                        </div>
                    </div>
                </div>
            </section-block>
        </div>
    </marketing-layout>
</template>

<script>
    import Marketing from '@/layouts/Marketing'
    import { i18n } from '@/utils/i18n'
    import SectionBlock from '@/components/SectionBlock'
    import { contact_info } from '@/utils/contact-info-dictionary'
    import { appSessionStorage, sessionStorageKey } from '@/utils/storage'
    import { Flows } from '@/flow/flowController'
    import SectionHeader from '@/components/SectionHeader'
    import OriginationInviteForm from '@/components/OriginationInviteForm'
    import { experimentsMixin } from '@/mixins/experimentsMixin'
    import CardHandSmall from '@/components/landing/CardHandSmall'
    import BenefitsSection from '@/components/landing/BenefitsSection'
    import HowItWorksSection from '@/components/landing/HowItWorksSection'
    import LimitsSection from '@/components/landing/LimitsSection'
    import HomeEquitySection from '@/components/landing/HomeEquitySection'
    import DividerLine from '@/components/DividerLine'
    import HomeImprovementSection from '@/components/landing/HomeImprovementSection'
    import UnlimitedCashbackSection from '@/components/landing/UnlimitedCashbackSection'
    import FaqSection from '@/components/landing/FaqSection'
    import FeesSection from '@/components/landing/FeesSection'
    import BalanceTransferSection from '@/components/landing/BalanceTransferSection'
    import CardHandMediumLarge from '@/components/landing/CardHandMediumLarge'
    import CashOutSection from '@/components/landing/CashOutSection'
    import Testimonial from '@/components/landing/Testimonial'
    import CardComparisonSection from '@/components/landing/CardComparisonSection'
    import AboutUsSection from '@/components/landing/AboutUsSection'
    import { logger } from '@/utils/logger'
    import { reloadPageWithCleanState } from '@/utils/routerUtils'

    const SITE_VERSION = '2.5'

    export default {
        name: 'Batch15LandingDefault',
        metaInfo: {
            title: 'Aven HELOC Card: A Credit Card backed by Home Equity',
            meta: [
                { name: 'description', content: String(i18n.t('pages.marketing.landing.description')) },
                { vmid: 'robots', name: 'robots', content: 'noindex' },
            ],
        },
        props: {
            codeRequired: {
                type: Boolean,
                required: true,
            },
            showForgotCodeOption: {
                type: Boolean,
                required: true,
            },
        },
        components: {
            'about-us-section': AboutUsSection,
            'card-comparison-section': CardComparisonSection,
            testimonial: Testimonial,
            'cash-out-section': CashOutSection,
            'card-hand-medium-large': CardHandMediumLarge,
            'balance-transfer-section': BalanceTransferSection,
            'fees-section': FeesSection,
            'faq-section': FaqSection,
            'home-improvement-section': HomeImprovementSection,
            'divider-line': DividerLine,
            'home-equity-section': HomeEquitySection,
            'unlimited-cashback-section': UnlimitedCashbackSection,
            'limits-section': LimitsSection,
            'how-it-works-section': HowItWorksSection,
            'benefits-section': BenefitsSection,
            'card-hand-small': CardHandSmall,
            'origination-invite-form': OriginationInviteForm,
            'section-header': SectionHeader,
            'section-block': SectionBlock,
            'marketing-layout': Marketing,
        },
        mixins: [experimentsMixin],
        created: function () {
            appSessionStorage.setItem(sessionStorageKey.currentFlow, Flows.origination)
        },
        mounted: function () {
            if (appSessionStorage.getItem(sessionStorageKey.jwtTokens)) {
                logger.info(`User went to landing page with jwt tokens! Clearing their stale state`)
                return reloadPageWithCleanState()
            }

            this.$logEvent('view_landing_default', { query: window.location.search, version: SITE_VERSION })
        },
        computed: {
            smsHref() {
                return `sms:${contact_info.sms}`
            },
        },
    }
</script>

<style lang="scss">
    @import '../../../../styles/pages/marketing/landingV2_5';
</style>
