<template>
    <section class="d-lg-none ps-3">
        <picture>
            <source
                srcset="@/assets/images/pages/marketing/landingV2_5/aven-credit-card-hand-sm.webp"
                type="image/webp"
            >
            <source
                srcset="@/assets/images/pages/marketing/landingV2_5/aven-credit-card-hand-sm.jpg"
                type="image/jpeg"
            >
            <img
                src="@/assets/images/pages/marketing/landingV2_5/aven-credit-card-hand-sm.jpg"
                class="img-fluid w-100"
                alt="Aven Card"
            >
        </picture>
    </section>
</template>

<script>
    export default {
        name: 'CardHandSmall',
    }
</script>
