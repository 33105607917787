<template>
    <section-block>
        <div class="container">
            <div class="row align-items-center">
                <div class="col-sm order-md-2">
                    <section-header
                        variant="text-primary"
                        underline
                    >
                        Home Equity
                    </section-header>
                    <h3 class="text-responsive mt-2">
                        {{ title }}
                    </h3>
                    <h5 class="text-responsive fw-light">
                        {{ subtitle }}
                    </h5>
                </div>
                <div class="col-md">
                    <picture>
                        <source
                            srcset="../../assets/images/pages/marketing/landingV2_5/home-equity-aven.webp"
                            type="image/webp"
                        >
                        <source
                            srcset="../../assets/images/pages/marketing/landingV2_5/home-equity-aven.png"
                            type="image/png"
                        >
                        <img
                            class="w-100 img-fluid mw-illustration"
                            src="../../assets/images/pages/marketing/landingV2_5/home-equity-aven.png"
                            alt="Home Equity"
                        >
                    </picture>
                </div>
            </div>
        </div>
    </section-block>
</template>

<script>
    import SectionHeader from '../SectionHeader'
    import SectionBlock from '../SectionBlock'
    export default {
        name: 'HomeEquitySection',
        props: {
            title: { type: String, default: 'Easiest way to use home equity.' },
            subtitle: { type: String, default: 'Using your home as collateral, like a mortgage, we can get you home equity rates, all in 15 minutes and entirely online.' },
        },
        components: { SectionBlock, SectionHeader },
    }
</script>

<style scoped></style>
