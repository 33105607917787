<template>
    <div>
        <img
            src="@/assets/images/global/double_quotes.svg"
            alt="Testimonial"
        >
        <h4 class="text-responsive mt-2">
            {{ header }}
        </h4>
        <p class="fw-bold mb-0">
            {{ name }}
        </p>
        <p class="">
            {{ location }}
        </p>
    </div>
</template>

<script>
    export default {
        name: 'Testimonial',
        props: {
            header: { type: String, required: true },
            name: { type: String, required: true },
            location: { type: String, required: true },
        },
    }
</script>

<style scoped></style>
