<template>
    <div
        @click="handleClick"
        class="faq mb-5"
        :class="{ expanded }"
    >
        <div class="question">
            <p class="fw-bold text-responsive">
                {{ question }}
            </p>
            <img
                src="../assets/images/components/faq/Arrow.svg"
                alt="Arrow"
                class="ms-2"
            >
        </div>
        <div
            v-show="expanded"
            class="answer mt-1"
            v-html="answer"
        />
    </div>
</template>

<script>
    export default {
        name: 'Faq',
        props: {
            question: String,
            answer: String,
        },
        data: function () {
            return {
                expanded: false,
            }
        },
        methods: {
            handleClick: function () {
                this.expanded = !this.expanded
            },
        },
    }
</script>

<style lang="scss">
    @import '../styles/components/faqExperimental';
</style>
