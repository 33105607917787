<template>
    <section-block>
        <div class="container">
            <div class="row align-items-center">
                <div class="col-12 col-md-6 order-md-1 offset-md-1">
                    <h1 class="text-responsive">
                        Send cash to your bank
                    </h1>
                    <h5 class="text-responsive fw-light">
                        Directly deposit cash from your Aven card to your bank account at the same low rate. Some charges may apply<sup><a href="#footnote_1">1</a></sup>
                    </h5>
                </div>
                <div class="col-12 col-md-5 order-md-0 mt-3 mt-md-0">
                    <img
                        src="@/assets/images/pages/marketing/landing/CashOut@2x.jpg"
                        class="img-fluid"
                        alt="Cashout"
                    >
                </div>
            </div>
        </div>
    </section-block>
</template>

<script>
    import SectionBlock from '../SectionBlock'
    export default {
        name: 'CashOutSection',
        components: { SectionBlock },
    }
</script>
