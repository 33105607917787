<template>
    <section-block>
        <div class="container">
            <div class="row align-items-center">
                <div class="col-12 col-md-8 offset-md-2">
                    <h1 class="text-responsive text-md-center">
                        All the benefits of a Visa Signature
                    </h1>
                </div>
            </div>
            <div class="row mt-3 mt-4">
                <div class="col-12 col-md-10 offset-md-1">
                    <div class="row gx-3">
                        <div class="col-12 col-md-6 mb-3">
                            <div class="feature bg-white">
                                <p>Accepted Globally</p>
                                <img
                                    src="@/assets/images/pages/marketing/landing/CheckMark.svg"
                                    width="24"
                                    height="24"
                                    alt="asdsd"
                                >
                            </div>
                        </div>
                        <div class="col-12 col-md-6 mb-3">
                            <div class="feature bg-white">
                                <p>US-based Customer Service</p>
                                <img
                                    src="@/assets/images/pages/marketing/landing/CheckMark.svg"
                                    width="24"
                                    height="24"
                                    alt="asdsd"
                                >
                            </div>
                        </div>
                    </div>
                    <div class="row gx-3">
                        <div class="col-12 col-md-6 mb-3">
                            <div class="feature bg-white">
                                <p>Visa Zero Liability</p>
                                <img
                                    src="@/assets/images/pages/marketing/landing/CheckMark.svg"
                                    width="24"
                                    height="24"
                                    alt="asdsd"
                                >
                            </div>
                        </div>
                        <div class="col-12 col-md-6 mb-3">
                            <div class="feature bg-white">
                                <p>Fraudulent Purchase Protection</p>
                                <img
                                    src="@/assets/images/pages/marketing/landing/CheckMark.svg"
                                    width="24"
                                    height="24"
                                    alt="asdsd"
                                >
                            </div>
                        </div>
                    </div>
                    <div class="row gx-3">
                        <div class="col-12 col-md-6 mb-3">
                            <div class="feature bg-white">
                                <p>Simple Web & Mobile Apps</p>
                                <img
                                    src="@/assets/images/pages/marketing/landing/CheckMark.svg"
                                    width="24"
                                    height="24"
                                    alt="asdsd"
                                >
                            </div>
                        </div>
                        <div class="col-12 col-md-6 mb-3">
                            <div class="feature bg-white">
                                <p>1.5% Unlimited Cashback</p>
                                <img
                                    src="@/assets/images/pages/marketing/landing/CheckMark.svg"
                                    width="24"
                                    height="24"
                                    alt="Check Mark"
                                >
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section-block>
</template>

<script>
    import SectionBlock from '../SectionBlock'
    export default {
        name: 'BenefitsSection',
        components: { SectionBlock },
    }
</script>

<style lang="scss">
    @import '../../styles/components/benefitsSection';
</style>
