<template>
    <section-block>
        <div class="container">
            <div class="row align-items-center">
                <div class="col-12 col-md-7">
                    <h1 class="text-responsive">
                        $0 to get. <br>
                        No annual or origination fee<sup><a href="#footnote_1">1</a></sup>
                    </h1>
                    <h5 class="text-responsive fw-light mt-2 mt-md-3">
                        Typical home equity products can cost thousands of dollars to originate on top of the weeks long process. With Aven it costs $0 to get a card, as fast as 15 minutes.
                    </h5>
                </div>
                <div class="col-12 col-md-5 mt-4 mt-md-0">
                    <div class="list">
                        <div class="">
                            <p>Origination Fee</p>
                            <p class="fw-bold">
                                $0
                            </p>
                        </div>
                        <div class="">
                            <p>Annual Fee</p>
                            <p class="fw-bold">
                                $0
                            </p>
                        </div>
                        <div class="">
                            <p>Appraisal Fee</p>
                            <p class="fw-bold">
                                $0
                            </p>
                        </div>
                        <div class="">
                            <p>Notary Fee</p>
                            <p class="fw-bold">
                                $0
                            </p>
                        </div>
                        <div class="">
                            <p>Recording Fee</p>
                            <p class="fw-bold">
                                $0
                            </p>
                        </div>
                        <div class="">
                            <p>Escrow Fee</p>
                            <p class="fw-bold">
                                $0
                            </p>
                        </div>
                        <div class="">
                            <p>Prepayment Penalty</p>
                            <p class="fw-bold">
                                $0
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section-block>
</template>

<script>
    import SectionBlock from '../SectionBlock'

    export default {
        name: 'FeesSection',
        components: { SectionBlock },
    }
</script>

<style lang="scss">
    @import '../../styles/components/feesSection';
</style>
