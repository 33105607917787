<template>
    <section-block>
        <div class="container">
            <div class="row">
                <div class="col-12 col-md-8 offset-md-2">
                    <h1 class="text-responsive">
                        Questions?
                    </h1>
                </div>
            </div>
            <div class="row mt-3 mt-md-5">
                <div class="col-12 col-md-8 offset-md-2">
                    <div v-if="hasSlot">
                        <!-- Slot can be used to for faqs -->
                        <slot />
                    </div>
                    <div v-else>
                        <faq
                            :question="$t('components.faq.homeEquityLoan.question')"
                            :answer="$t(`experiments.${heraclesParameter.EXPERIMENT_NAME}.homeEquityLoan.answerHtml`)"
                        />
                        <faq
                            :question="$t('components.faq.homeImpact.question')"
                            :answer="$t('components.faq.homeImpact.answerHtml')"
                        />
                        <faq
                            :question="$t('components.faq.criteria.question')"
                            :answer="$t('components.faq.criteria.answerHtml')"
                        />
                        <faq
                            :question="$t('components.faq.helocOrCc.question')"
                            :answer="$t(`components.faq.helocOrCc.answerHtml`)"
                        />
                    </div>
                </div>
            </div>
        </div>
    </section-block>
</template>

<script>
    import SectionBlock from '../SectionBlock'
    import Faq from '../FaqExperimental'
    import { experimentsMixin } from '@/mixins/experimentsMixin'

    export default {
        name: 'FaqSection',
        components: { Faq, SectionBlock },
        mixins: [experimentsMixin],
        computed: {
            hasSlot: function () {
                return !!this.$slots.default
            },
        },
    }
</script>

<style scoped></style>
