<template>
    <div class="col-md-6 position-absolute aven-hand p-0">
        <picture>
            <source
                srcset="@/assets/images/pages/marketing/landingV2_5/aven-credit-card-hand-md.webp"
                type="image/webp"
            >
            <source
                srcset="@/assets/images/pages/marketing/landingV2_5/aven-credit-card-hand-md.jpg"
                type="image/jpeg"
            >
            <img
                src="@/assets/images/pages/marketing/landingV2_5/aven-credit-card-hand-md.jpg"
                class="w-100 img-fluid d-none d-lg-block d-xl-none"
                alt="Aven Card"
            >
        </picture>
        <picture>
            <source
                srcset="@/assets/images/pages/marketing/landingV2_5/aven-credit-card-hand-lg.webp"
                type="image/webp"
            >
            <source
                srcset="@/assets/images/pages/marketing/landingV2_5/aven-credit-card-hand-lg.jpg"
                type="image/jpeg"
            >
            <img
                src="@/assets/images/pages/marketing/landingV2_5/aven-credit-card-hand-lg.jpg"
                class="w-100 img-fluid d-none d-xl-block"
                alt="Aven Card"
            >
        </picture>
    </div>
</template>

<script>
    export default {
        name: 'CardHandMediumLarge',
    }
</script>
