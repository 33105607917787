<template>
    <section-block>
        <div class="container">
            <div class="row">
                <div class="col12 col-md-6">
                    <h1 class="text-responsive mb-2 mb-md-3">
                        Qualify in seconds.
                    </h1>
                    <h1 class="text-responsive mb-2 mb-md-5">
                        Close in minutes.
                    </h1>
                    <h5 class="text-responsive text-responsive fw-light">
                        As fast as 15 minutes from start to close, all from the comfort of your home.
                    </h5>
                </div>
                <div class="col-12 col-md-6">
                    <img
                        src="@/assets/images/pages/marketing/landing/HowItWorksHero@2x.jpg"
                        class="img-fluid"
                        alt="100% Online"
                    >
                </div>
            </div>
            <div class="row mt-5 mt-md-9 align-items-center">
                <div class="col-12 col-md-6 order-md-1">
                    <div>
                        <div class="d-flex">
                            <h5 class="me-2 me-md-3">
                                1.
                            </h5>
                            <div>
                                <h5 class="mb-0 mb-md-1">
                                    Check Offer
                                </h5>
                                <p>Answer a few simple questions to quickly confirm your identity and home. <strong>Does not affect your credit score.</strong></p>
                            </div>
                        </div>
                    </div>
                    <div class="mt-3">
                        <div class="d-flex">
                            <h5 class="me-2 me-md-3">
                                2.
                            </h5>
                            <div>
                                <h5 class="mb-0 mb-md-1">
                                    Confirm Income
                                </h5>
                                <p>A flexible yet simple process to instantly verify your bank account or pay stubs. You pick which works for you.</p>
                            </div>
                        </div>
                    </div>
                    <div class="mt-3">
                        <div class="d-flex">
                            <h5 class="me-2 me-md-3">
                                3.
                            </h5>
                            <div>
                                <h5 class="mb-0 mb-md-1">
                                    Sign 100% Online
                                </h5>
                                <p>Simply and easily connect with our notary to sign your documents digitally.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-6 order-md-0 mt-3 mt-md-0">
                    <img
                        src="@/assets/images/pages/marketing/landing/HowitWorksSigning@2x.jpg"
                        class="img-fluid"
                        alt="100% Online"
                    >
                </div>
            </div>
        </div>
    </section-block>
</template>

<script>
    import SectionBlock from '../SectionBlock'
    export default {
        name: 'HowItWorksSection',
        components: { SectionBlock },
    }
</script>

<style scoped></style>
