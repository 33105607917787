<template>
    <section-block>
        <div class="container">
            <div class="row align-items-center">
                <div class="col-12 col-md-6 order-md-1">
                    <h1 class="text-responsive mb-2 mb-md-4">
                        Up to {{ lineSizeLimit }}
                    </h1>
                    <h5 class="text-responsive fw-light">
                        Get the benefits of a high credit limit with the flexibility to only use what you need. Starting at $5K.
                    </h5>
                </div>
                <div class="col-12 col-md-6 order-md-0 mt-3 mt-md-0">
                    <img
                        class="img-fluid"
                        src="@/assets/images/pages/marketing/landing/100K@2x.jpg"
                        alt="Limits"
                    >
                </div>
            </div>
        </div>
    </section-block>
</template>

<script>
    import SectionBlock from '../SectionBlock'

    export default {
        name: 'LimitsSection',
        components: { SectionBlock },
        props: {
            lineSizeLimit: {
                type: String,
                required: false,
                default: '$100K',
            },
        },
    }
</script>
