<template>
    <section-block>
        <div class="container">
            <div class="row align-items-center">
                <div class="col-12 col-md-6 offset-md-1 order-md-1 mb-5">
                    <h1 class="text-responsive mb-2 mt-2">
                        Backed by an FDIC insured bank
                    </h1>
                    <h5 class="text-responsive fw-light">
                        We are partnered with Coastal Community Bank, member FDIC, pursuant to license by Visa USA Inc. You benefit from our innovation, great pricing, and simple convenience with the
                        trust of a traditional FDIC-insured bank.
                    </h5>
                </div>
                <div class="col-12 col-md-5 order-md-0">
                    <img
                        src="@/assets/images/pages/marketing/landing/Bank@2x.jpg"
                        alt="Visa Card"
                        class="img-fluid"
                    >
                </div>
            </div>
        </div>
    </section-block>
</template>

<script>
    import SectionBlock from '../SectionBlock'

    export default {
        name: 'AboutUsSection',
        components: { SectionBlock },
    }
</script>
