<template>
    <section-block>
        <div class="container">
            <div class="row align-items-start">
                <div class="col-sm">
                    <section-header
                        variant="text-primary"
                        underline
                    >
                        Home Improvement
                    </section-header>
                    <h3 class="text-responsive my-2">
                        Great for home improvements
                    </h3>
                    <h5 class="text-responsive fw-light">
                        Broken pipe? Bathroom upgrade? Use our fast and simple access to your home equity to start that home improvement project you have wanted to do. Big or small.
                    </h5>
                </div>
                <div class="d-block d-md-none container my-5">
                    <hr>
                </div>
                <div class="col-sm">
                    <section-header
                        variant="text-primary"
                        underline
                    >
                        Tax Savings
                    </section-header>
                    <h3 class="text-responsive my-2">
                        Tax deductable interest on your home projects
                    </h3>
                    <h5 class="text-responsive fw-light mb-0">
                        Did you know home improvement projects may be considered tax deductible! Consult a tax advisor regarding the deductibility of interest and charges.
                    </h5>
                </div>
            </div>
        </div>
    </section-block>
</template>

<script>
    import SectionHeader from '../SectionHeader'
    import SectionBlock from '../SectionBlock'
    export default {
        name: 'HomeImprovementSection',
        components: { SectionBlock, SectionHeader },
    }
</script>

<style scoped></style>
