<template>
    <section-block>
        <div class="container">
            <div class="row align-items-center">
                <div class="col-sm order-md-2">
                    <section-header
                        variant="text-primary"
                        underline
                    >
                        Balance Transfer
                    </section-header>
                    <h3 class="text-responsive mb-2 mt-2">
                        No balance transfer fee<sup><a href="#footnote_1">1</a></sup>
                    </h3>
                    <h5 class="text-responsive fw-light">
                        Transfer high-interest debt to a low APR with our $0 transfer fee and save big on your monthly payment.
                    </h5>
                </div>
                <div class="col-md">
                    <picture>
                        <source
                            srcset="../../assets/images/pages/marketing/landingV2_5/balance-transfer-aven.webp"
                            type="image/webp"
                        >
                        <source
                            srcset="../../assets/images/pages/marketing/landingV2_5/balance-transfer-aven.png"
                            type="image/png"
                        >
                        <img
                            class="w-100 img-fluid mw-illustration"
                            src="../../assets/images/pages/marketing/landingV2_5/balance-transfer-aven.png"
                            alt="Balance Transfer"
                        >
                    </picture>
                </div>
            </div>
        </div>
    </section-block>
</template>

<script>
    import SectionBlock from '../SectionBlock'
    import SectionHeader from '../SectionHeader'
    export default {
        name: 'BalanceTransferSection',
        components: { SectionHeader, SectionBlock },
    }
</script>

<style scoped></style>
